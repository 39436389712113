<template>
  <div :class="$style['panel']">
    <div :class="$style['panel__type']">
      <slot name="type"></slot>
    </div>
    <div :class="$style['panel__title-wrap']">
      <div :class="$style['panel__title']">
        <span>
          <slot name="title"></slot>
        </span>
      </div>
    </div>
    <div :class="$style['panel__buttons']">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ezfWizardPanel',
};
</script>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
