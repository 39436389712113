<script>
export default {
  name: 'ezfWizardFunnelFunnel',
  props: ['title', 'value', 'model', 'slug'],
};
</script>

<template>
  <ez-atom-box class="mt-4">
    <template slot="header">
      <div :class="$style['box-header-wrap']">
        <i18n
          path="wizard.funnel_type_is"
          tag="div"
          :class="[$style['heading'], $style['is-thin']]"
        >
          <span :class="$style['weight-500']">
            {{ $t(`wizard.funnels.${value}.title`) }}
          </span>
        </i18n>

        <ez-atom-button-group :class="$style['box-header-buttons']">
          <ez-atom-button
            color="success"
            @click="
              $router.push({
                name: 'wizard-themes',
                params: { type: slug }
              })
            "
            :href="
              $router.resolve({
                name: 'wizard-themes',
                params: { type: slug }
              }).href
            "
            data-test="continue"
          >
            {{ $t(`wizard.continue`) }}
          </ez-atom-button>
        </ez-atom-button-group>
      </div>
    </template>
  </ez-atom-box>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
