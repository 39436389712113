<script>
import FUNNELS from '@/enums/funnels';
import ezfPageHeader from '@/pages/layout/header.vue';
import ezfPageFooter from '@/pages/layout/footer.vue';
import WzTypes from './components/funnels/types.vue';
import WzView from './components/funnels/view.vue';
import WzList from './components/funnels/list.vue';
import WzCards from './components/funnels/cards.vue';

export default {
  name: 'ezfWizard1FunnelsPage',
  components: {
    ezfPageHeader,
    ezfPageFooter,
    WzTypes,
    WzView,
    WzList,
    WzCards,
  },
  data() {
    return {
      type: this.$route.query.type || 'all',
      view: this.$route.query.view || 'list',
    };
  },
  computed: {
    funnels() {
      return FUNNELS.filter((f) => f.types.includes(this.type));
    },
    isViewList() {
      return this.view === 'list';
    },
  },
};
</script>

<template>
  <ez-layout name="funnels">
    <ezf-page-header slot="header" />
    <ez-atom-container slot="content" :class="$style['content-new-wizard']">
      <ez-atom-row>
        <ez-atom-col>
          <div :class="$style['filter-box']">
            <wz-types v-model="type" />
            <wz-view v-model="view" />
          </div>
        </ez-atom-col>
      </ez-atom-row>

      <wz-list v-if="view === 'list'" :funnels="funnels" />
      <wz-cards v-else :funnels="funnels" />
    </ez-atom-container>
    <ezf-page-footer slot="footer" />
  </ez-layout>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
