<script>
import { mapGetters, mapActions } from 'vuex';
import { GET_STEPS_WITH_TEMPLATES, GET_TEMPLATES_BY_TYPE } from '@/store/wizard/themes/getters';
import { LOAD_TEMPLATES } from '@/store/wizard/themes/actions';
import wzPrewiewStep from './step.vue';

export default {
  name: 'ezfWizardThemesPrewiewSteps',
  props: ['theme'],
  components: {
    wzPrewiewStep,
  },
  computed: {
    ...mapGetters('wizard/themes', {
      stepsWithTemplates: GET_STEPS_WITH_TEMPLATES,
      templatesByType: GET_TEMPLATES_BY_TYPE,
    }),
  },
  methods: {
    ...mapActions('wizard/themes', [LOAD_TEMPLATES]),
  },
  mounted() {
    this[LOAD_TEMPLATES](this.theme.id);
  },
};
</script>

<template>
  <div :class="$style['fixed-right-box__inner']">
    <!-- header -->
    <div :class="$style['fixed-right-box__header']">
      <div :class="[$style['heading'], $style['is-big']]">
        {{ theme.name }}
      </div>
      <ez-atom-button
        color="neutral"
        icon="math-default-false"
        @click="$emit('close')"
        data-test="popup-close"
      ></ez-atom-button>
    </div>
    <!-- END header -->
    <!-- content -->
    <div :class="$style['fixed-right-box__content']">
      <wz-prewiew-step
        v-for="step of stepsWithTemplates"
        :key="step.num"
        :title="$t(`wizard.steps.${step.slug}.title`)"
        :templates="templatesByType[step.num]"
        :data-test="`template-step-${step.slug}`"
      />
    </div>
    <!-- END content -->
    <!-- footer -->
    <div :class="$style['fixed-right-box__footer']">
      <ez-atom-button
        class="ml-auto"
        color="funnel"
        @click="$emit('use-theme', theme.id)"
        data-test="popup-use-template"
      >
        {{ $t("wizard.useTheme") }}
      </ez-atom-button>
    </div>
    <!-- END footer -->
  </div>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
