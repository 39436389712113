<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { LOAD_DOMAINS } from '@/store/actions';
import { LOAD_GROUPS, CREATE_GROUP } from '@/store/funnels/actions';
import { ALL_GROUPS } from '@/store/funnels/getters';
import localesListMixin from '@/mixins/localesList';
import nameAndSlugMixin from '@/mixins/nameAndSlug';
import editGroupDialog from '@/components/dialogs/changeGroup';

export default {
  name: 'ezfWizardFunnelForm',
  inject: ['$validator'],
  mixins: [
    localesListMixin,
    nameAndSlugMixin,
  ],
  computed: {
    ...mapState(['mainUser', 'domains']),
    ...mapFields('wizard/form', ['name', 'slug', 'groupId', 'domainId', 'locale']),
    ...mapGetters('funnels', [ALL_GROUPS]),
    groups() {
      return [{}, ...this[ALL_GROUPS]];
    },
  },
  methods: {
    ...mapActions([LOAD_DOMAINS]),
    ...mapActions('funnels', [LOAD_GROUPS, CREATE_GROUP]),

    async createNewGroup() {
      const group = await editGroupDialog();
      if (group) {
        const groupId = await this[CREATE_GROUP](group);
        if (groupId) {
          this.groupId = groupId;
        }
      }
    },
  },
  created() {
    this[LOAD_DOMAINS](this.mainUser);
    this[LOAD_GROUPS]();

    // fix issue #629756830
    if (this.groupId && !this[ALL_GROUPS].find((g) => g.id === this.groupId)) {
      this.groupId = null;
    }

    if (!this.locale && this.mainUserLocale) {
      this.locale = this.mainUserLocale;
    }
  },
};
</script>

<template>
  <div>
    <ez-row class="pt-2 pb-3">
      <ez-col>
        <ez-atom-form-field
          :label="$t('wizard.form.funnelName.label')"
          :status="errors.has('name') ? 'error' : ''"
          :message="errors.first('name')"
        >
          <ez-atom-input
            name="name"
            v-model="name"
            v-validate="'required'"
            :status="errors.has('name') ? 'error' : ''"
            :data-vv-as="$t('wizard.form.funnelName.label')"
            data-test="funnel-type"
          />
        </ez-atom-form-field>
      </ez-col>
    </ez-row>
    <ez-row :gutter="16" class="py-2">
      <ez-col>
        <ez-atom-form-field :label="$t('wizard.form.domain.label')">
          <ez-atom-select
            name="domain"
            v-model="domainId"
            :data-vv-as="$t('wizard.form.domain.label')"
            :options="domains"
            item-label="name"
            track-by="id"
            is-single
            data-test="domain"
          />
        </ez-atom-form-field>
      </ez-col>
      <ez-col>
        <ez-atom-form-field
          :label="$t('wizard.form.slug.label')"
          :status="errors.has('slug') ? 'error' : ''"
          :message="errors.first('slug')"
        >
          <ez-atom-input
            name="slug"
            v-model="slug"
            v-validate="{
              required: true,
              regex: /^[-a-z0-9_]+$/
            }"
            :data-vv-as="$t('wizard.form.slug.label')"
            @input.native="slugInput"
            :status="errors.has('slug') ? 'error' : ''"
            data-test="slug"
          />
        </ez-atom-form-field>
      </ez-col>
    </ez-row>
    <ez-row :gutter="16" class="pt-2 pb-3">
      <ez-col>
        <ez-atom-form-field :label="$t('wizard.form.group.label')">
          <ez-atom-select
            name="group"
            v-model="groupId"
            :data-vv-as="$t('wizard.form.group.label')"
            :options="groups"
            item-label="name"
            track-by="id"
            allow-empty
            is-single
            data-test="group"
          />
        </ez-atom-form-field>

        <a :class="$style['form-field-link']" @click="createNewGroup">{{
          $t("links.createNewGroup")
        }}</a>
      </ez-col>
      <ez-col>
        <ez-atom-form-field :label="$t('wizard.form.language.label')">
          <ez-atom-select
            name="locale"
            v-model="locale"
            v-validate="'required'"
            :data-vv-as="$t('wizard.form.language.label')"
            :options="localesList"
            is-single
            item-label="label"
            track-by="value"
            :allow-empty="false"
            :searchable="false"
            data-test="language"
          />
        </ez-atom-form-field>
      </ez-col>
    </ez-row>
  </div>
</template>

<style lang="scss" src="../styles/wizard.scss" module></style>
