import { render, staticRenderFns } from "./template.vue?vue&type=template&id=1c9c5eae&scoped=true&"
import script from "./template.vue?vue&type=script&lang=js&"
export * from "./template.vue?vue&type=script&lang=js&"
import style0 from "@/pages/wizard/styles/wizard.scss?vue&type=style&index=0&lang=scss&module=true&"
import style1 from "./template.vue?vue&type=style&index=1&id=1c9c5eae&scoped=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1c9c5eae",
  null
  
)

export default component.exports