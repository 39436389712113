<script>
import swiperMixin from '@/mixins/swiper';
import ezfWizardArrows from '../../arrows.vue';
import wzPrewiewTemplate from './template.vue';

export default {
  name: 'ezfWizardThemesPrewiewStep',
  props: ['title', 'templates'],
  mixins: [swiperMixin],
  components: {
    wzPrewiewTemplate,
    ezfWizardArrows,
  },
};
</script>

<template>
  <div :class="$style['pages-slider-box']">
    <!-- slider header -->
    <div :class="$style['pages-slider-box__header']">
      <div :class="$style['heading']">
        {{ title }}
      </div>
      <ezf-wizard-arrows v-bind="{ isBeginning, isEnd }" ref="arrows" />
    </div>
    <!-- slider content -->
    <div :class="$style['pages-slider-box__content']">
      <div :class="$style['pages-slider']" ref="container">
        <div :class="[$style['pages-slider__wrapper'], 'swiper-wrapper']">
          <div
            v-for="(template, n) in templates"
            :key="n"
            :class="[$style['pages-slider-slide'], 'swiper-slide']"
            :data-test="`step-template-${n}`"
          >
            <wz-prewiew-template
              :preview="template.preview"
              :id="template['template-id']"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
