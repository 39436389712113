<script>
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { GET_STEPS_WITH_TEMPLATES, GET_TEMPLATES_BY_TYPE } from '@/store/wizard/themes/getters';
import { LOAD_TEMPLATES } from '@/store/wizard/themes/actions';

import ezfPageHeader from '@/pages/layout/header.vue';
import ezfWizardThemesStep from '@/pages/wizard/components/themes/step.vue';
import ezLoadingBlock from '@/components/ezLoadingBlock.vue';

export default {
  name: 'ezfWizard3TemplatesPage',
  components: {
    ezfPageHeader,
    ezfWizardThemesStep,
    ezLoadingBlock,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters('wizard/themes', {
      stepsWithTemplates: GET_STEPS_WITH_TEMPLATES,
      templatesByType: GET_TEMPLATES_BY_TYPE,
    }),
    ...mapFields('wizard/form', ['templates']),
    ...mapFields('wizard/themes', ['selectedTemplates']),
    previousRoute() {
      return { name: 'wizard-themes', query: { selected: this.$route.params.theme } };
    },
  },
  methods: {
    ...mapActions('wizard/themes', [LOAD_TEMPLATES]),
    selectTemplates() {
      this.templates = this.stepsWithTemplates.map((step) => this.selectedTemplates[step.num]);
      this.$router.push({ name: 'wizard-finish' });
    },
    async init() {
      this.loading = true;
      await this[LOAD_TEMPLATES](this.$route.params.theme);
      let update = false;
      const selectedTemplates = { ...this.selectedTemplates };
      for (const step of this.stepsWithTemplates) {
        const selected = selectedTemplates[step.num];
        const templates = this.templatesByType[step.num];
        if (!selected || !templates.some((template) => selected === template['template-id'])) {
          selectedTemplates[step.num] = templates[0]['template-id'];
          update = true;
        }
      }
      if (update) {
        this.selectedTemplates = selectedTemplates;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<template>
  <ez-layout name="funnels">
    <ezf-page-header slot="header" />
    <ez-atom-container slot="content" :class="$style['content-new-wizard']">
      <ez-loading-block :loading="loading">
        <ez-atom-row class="mt-5 flex-wrap" :gutter="24">
          <ezf-wizard-themes-step
            v-for="step in stepsWithTemplates"
            :key="step.num"
            :step="step"
            :templates="templatesByType[step.num]"
            :data-test="`template-step-${step.slug}`"
          />
        </ez-atom-row>
      </ez-loading-block>
    </ez-atom-container>

    <ezf-wizard-footer slot="footer">
      <ez-atom-button
        variation="outline"
        color="neutral"
        @click="$router.push(previousRoute)"
        :href="$router.resolve(previousRoute).href"
        data-test="go-back"
      >
        {{ $t("wizard.back") }}
      </ez-atom-button>
      <ez-atom-button
        color="funnel"
        @click="selectTemplates"
        data-test="use-pages"
      >
        {{ $t("wizard.useTemplates") }}
      </ez-atom-button>
    </ezf-wizard-footer>
  </ez-layout>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
