<script>
const imagesMain = require.context('@/assets/funnel-steps-main', false, /\.png$/);
const existsImages = imagesMain.keys();

export default {
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  name: 'ezfWizardFunnelImage',
  computed: {
    src() {
      for (const image of this.images) {
        const file = `./main-${image}`;
        if (existsImages.includes(file)) {
          return imagesMain(file);
        }
      }
      return imagesMain('./main-default.png');
    },
  },
};
</script>

<template>
  <ez-atom-col>
    <ez-atom-box>
      <template slot="content">
        <div :class="$style['image-box']">
          <div :class="$style['image-box__inner']">
            <img
              :class="$style['image-box__image']"
              v-if="src"
              :src="src"
              alt="Image"
            />
          </div>
        </div>
      </template>
    </ez-atom-box>
  </ez-atom-col>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
