<script>
const images = require.context('@/assets/funnel-steps', false, /\.png$/);
const existsImages = images.keys();

export default {
  props: ['image'],
  name: 'WzRadioOptionImage',
  computed: {
    src() {
      const image = `./${this.image}`;
      return existsImages.includes(image) ? images(image) : this.image;
    },
  },
};
</script>

<template>
  <img :src="src" alt="" />
</template>
