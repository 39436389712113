<script>
import WzRadioOptionImage from './radio_option_image.vue';

export default {
  name: 'ezfWizardRadio',
  props: ['title', 'name', 'i18n', 'options', 'model'],
  model: {
    prop: 'model',
    event: 'change',
  },
  components: {
    WzRadioOptionImage,
  },
  methods: {
    getTitle(option) {
      const key = `wizard.questions.${this.i18n}.options.${option.value}`;
      return this.$te(key) ? this.$t(key) : option.title;
    },
  },
};
</script>
<template>
  <ez-atom-box>
    <template slot="header">
      <div :class="$style['box-header-wrap']">
        <div :class="[$style['heading'], model && $style['is-gray']]">
          {{ title }}
        </div>
      </div>
    </template>

    <template slot="content">
      <ez-atom-row :gutter="16">
        <ez-atom-col
          v-for="option in options"
          :key="option.title"
          class="d-flex flex-column"
        >
          <ez-atom-select-box
            :model="model"
            :name="name"
            :id="`${name}_${option.value}`"
            @change="$emit('change', option.value)"
            :value="option.value"
            style="flex-grow: 1"
          >
            <div
              :class="$style['select-box-container']"
              :data-test="option.testAttr"
            >
              <div
                v-if="option.image && !model"
                :class="$style['select-box-container__image']"
              >
                <wz-radio-option-image :image="option.image" />
              </div>
              <div
                :class="[
                  $style['select-box-container__text'],
                  $style['is-align-center']
                ]"
              >
                <span>{{ getTitle(option) }}</span>
              </div>
            </div>
          </ez-atom-select-box>
        </ez-atom-col>
      </ez-atom-row>
    </template>
  </ez-atom-box>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
