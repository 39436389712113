import { render, staticRenderFns } from "./view.vue?vue&type=template&id=d78b9848&"
import script from "./view.vue?vue&type=script&lang=js&"
export * from "./view.vue?vue&type=script&lang=js&"
import style0 from "@/pages/wizard/styles/wizard.scss?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports