<script>
export default {
  name: 'ezfWizardHeader',
  data() {
    const query = {};
    const value = localStorage.getItem('wizard-funnels-view');
    if (value) {
      query.view = value;
    }

    return {
      route: {
        name: 'wizard-funnels',
        query,
      },
    };
  },
};
</script>

<template>
  <div :class="$style['box-header-wrap']">
    <div :class="[$style['heading'], $style['is-big']]">
      {{ $t("wizard.header") }}
    </div>
    <ez-atom-button-group :class="$style['box-header-buttons']">
      <ez-atom-button
        color="neutral"
        variation="outline"
        @click="$router.push(route)"
        :href="$router.resolve(route).href"
        data-test="skip"
      >
        {{ $t("wizard.skip") }}
      </ez-atom-button>
    </ez-atom-button-group>
  </div>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
