<script>
/* eslint-disable import/no-webpack-loader-syntax */
import AppFormIcon from '!!raw-loader!@/assets/funnel-map/step-icon-app.form.svg';
import CountdownIcon from '!!raw-loader!@/assets/funnel-map/step-icon-countdown.svg';
import DownsellIcon from '!!raw-loader!@/assets/funnel-map/step-icon-downsell.svg';
import ThankIcon from '!!raw-loader!@/assets/funnel-map/step-icon-thank.svg';
import WebOptIcon from '!!raw-loader!@/assets/funnel-map/step-icon-web.opt.svg';
import OrderformIcon from '!!raw-loader!@/assets/funnel-map/step-icon-orderform.svg';
import ReplayIcon from '!!raw-loader!@/assets/funnel-map/step-icon-replay.svg';
import SalespageIcon from '!!raw-loader!@/assets/funnel-map/step-icon-salespage.svg';
import UpsellIcon from '!!raw-loader!@/assets/funnel-map/step-icon-upsell.svg';
import WebinarIcon from '!!raw-loader!@/assets/funnel-map/step-icon-webinar.svg';

const MAP = new Map([
  ['canditatureform', AppFormIcon],
  ['webinar-countdown', CountdownIcon],
  ['downsell', DownsellIcon],
  ['optin-thank-you', ThankIcon],
  ['optinpage', WebOptIcon],
  ['order-form', OrderformIcon],
  ['webinar-replay', ReplayIcon],
  ['sales', SalespageIcon],
  ['order-thank-you', ThankIcon],
  ['upsell', UpsellIcon],
  ['Web. Optin', WebOptIcon],
  ['webinar-room-card', WebinarIcon],
]);

export default {
  name: 'ezfWizardFunnelMapIcon',
  props: ['step'],
  computed: {
    svg() {
      return MAP.get(this.step);
    },
  },
};
</script>

<template>
  <div :class="$style['step-image']" v-html="svg"></div>
</template>

<style lang="scss" module>
.step-image {
  width: 48px;
  height: 48px;
  margin: auto;
  fill: currentColor;
}
</style>
