<script>
export default {
  name: 'ezfWizardArrows',
  props: ['isBeginning', 'isEnd'],
};
</script>

<template>
  <ez-atom-button-group v-show="!(isBeginning && isEnd)">
    <ez-atom-button
      :color="isBeginning ? 'secondary' : 'neutral'"
      variation="text"
      icon="arrow-basic-left"
      :class="$style['funnel-control-arrow']"
      ref="slidePrev"
      :disabled="isBeginning"
    />
    <ez-atom-button
      :color="isEnd ? 'secondary' : 'neutral'"
      variation="text"
      icon="arrow-basic-right"
      :class="$style['funnel-control-arrow']"
      ref="slideNext"
      :disabled="isEnd"
    />
  </ez-atom-button-group>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
