<script>
import Vue from 'vue';
import ezAtom from '@dmant/ez-atom';
import ezfWizardFooter from './components/footer.vue';

Vue.use(ezAtom);
Vue.component(ezfWizardFooter.name, ezfWizardFooter);

export default {
  name: 'ezfWizardLayout',
};
</script>

<template>
  <router-view class="fix-header-container" />
</template>

<style class="fix-header-container" lang="scss">
.fix-header-container {
  .layout-header {
    .ez-container {
      @media (min-width: 992px) {
        max-width: 960px !important;
      }
      @media (min-width: 1350px) {
        max-width: 1292px !important;
      }
    }
  }
}
</style>
