<script>
import WzFunnelMapIcon from './funnel-map-icon.vue';

export default {
  name: 'ezfWizardFunnelMap',
  props: {
    model: { type: Array, default: () => [] },
    testAttrs: { type: Object, default: () => ({}) },
  },
  components: {
    WzFunnelMapIcon,
  },
};
</script>

<template>
  <div :class="$style['wizard-roadmap']">
    <div :class="[$style['wizard-roadmap__steps'], 'swiper-wrapper']">
      <!-- step -->
      <div
        v-for="(group, index) of model"
        :key="index"
        :class="[
          'swiper-slide',
          $style['wizard-step'],
          $style[`is-${group.group}`]
        ]"
      >
        <div :class="$style['wizard-step__header']">
          {{ $t(`wizard.funnelmap.groups.${group.group}`) }}
        </div>
        <div :class="$style['wizard-step__content']">
          <div :class="$style['wizard-step__row']">
            <div
              :class="$style['wizard-step__col']"
              v-for="(cols, colsIndex) of group.steps"
              :key="colsIndex"
            >
              <!-- small sqare -->
              <div
                :class="$style['wizard-step-item']"
                v-for="(step, stepIndex) of cols"
                :key="stepIndex"
              >
                <div :class="$style['wizard-step-item__content']">
                  <wz-funnel-map-icon :step="step" />
                </div>
                <div
                  :class="$style['wizard-step-item__title']"
                  :data-test="testAttrs[step]"
                >
                  {{
                    $te(`wizard.steps.${step}.funnelmap`)
                      ? $t(`wizard.steps.${step}.funnelmap`)
                      : step
                  }}
                </div>
              </div>
              <!-- END small sqare -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
