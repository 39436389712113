<script>
import { mapGetters, createNamespacedHelpers } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { LOAD_THEMES, USE_THEME } from '@/store/wizard/themes/actions';
import { GET_TYPE_QUERY } from '@/store/wizard/getters';

import ezfPageHeader from '@/pages/layout/header.vue';
import ezfWizardThemesPrewiewSteps from '@/pages/wizard/components/themes/preview/steps.vue';
import ezLoadingBlock from '@/components/ezLoadingBlock.vue';
import wzThemesCategories from './components/themes/categories.vue';

const { mapActions, mapState } = createNamespacedHelpers('wizard/themes');

export default {
  name: 'ezfWizard2ThemesPage',
  components: {
    ezfPageHeader,
    ezLoadingBlock,
    wzThemesCategories,
    ezfWizardThemesPrewiewSteps,
  },
  data() {
    return {
      showMenu: false,
      filterAll: false,
      currentFilter: 'All',
      previewTheme: null,
      loading: true,
    };
  },
  computed: {
    ...mapState({ allThemes: 'themes' }),
    ...mapGetters('wizard', { query: GET_TYPE_QUERY }),
    ...mapFields('wizard/form', ['themeId', 'templates']),
    currentTheme() {
      return this.$route.query.selected || this.themeId;
    },
    themes() {
      const { category } = this.$route.query;
      if (category) {
        return this.allThemes.filter((t) => t.categories.some(({ id }) => id === category));
      }
      return this.allThemes;
    },
    previousRoute() {
      return { name: 'wizard-goal', query: this.query };
    },
  },
  methods: {
    ...mapActions([LOAD_THEMES, USE_THEME]),
    changeSelected(value) {
      if (this.selected !== value) {
        this.$router.replace({ query: { ...this.$route.query, selected: value } });
      }
    },
    closeTemplates() {
      this.previewTheme = null;
    },
    showTemplates(theme) {
      this.previewTheme = theme;
    },
    needLuminosityFilter(theme) {
      return this.previewTheme && this.previewTheme.id !== theme.id;
    },
    useTheme(theme) {
      if (this.themeId !== theme) {
        this.themeId = theme;
        this.templates = [];
      }
      this.$router.push({
        name: 'wizard-templates',
        params: {
          type: this.$route.params.type,
          theme,
        },
      });
    },
    async loadThemes() {
      this.loading = true;
      await this[LOAD_THEMES]();
      this.loading = false;
    },
  },
  created() {
    this.loadThemes();
  },
};
</script>

<template>
  <ez-layout name="funnels">
    <ezf-page-header slot="header" />
    <div slot="content">
      <ez-atom-container :class="$style['content-new-wizard']">
        <ez-loading-block :loading="loading">
          <wz-themes-categories />

          <!-- row (filter) -->
          <ez-atom-row class="mt-5" :gutter="24">
            <ez-atom-col
              v-for="(theme, i) in themes"
              :key="'theme' + i"
              :size="6"
              :data-test="`theme-${i}`"
              class="mb-4"
            >
              <ez-atom-select-box
                :class="needLuminosityFilter(theme) && $style['is-luminosity']"
                :value="theme.id"
                :model="currentTheme"
                @change="changeSelected"
                :mark-unchecked="false"
                :styles="{
                  paddingContent: 0,
                  borderRadiusContent: 8,
                  iconSize: 20,
                  iconRatio: 32
                }"
                :name="'theme'"
                :id="'theme' + i"
              >
                <div :class="$style['theme-box']">
                  <div :class="$style['theme-box-image']">
                    <img
                      :class="$style['theme-box-image__image']"
                      :src="theme.image"
                      alt=""
                    />
                    <div :class="$style['theme-box-overlay']">
                      <ez-atom-button
                        color="neutral"
                        icon="security-show"
                        @click="showTemplates(theme)"
                        :class="$style['theme-box-overlay__button']"
                        :data-test="`show-pages-theme-${i}`"
                        >{{ $t("wizard.themes.showPages") }}</ez-atom-button
                      >
                    </div>
                  </div>
                  <div
                    :class="$style['theme-box-text']"
                    :data-test="`name-theme-${i}`"
                  >{{ theme.name }}</div>
                </div>
              </ez-atom-select-box>
            </ez-atom-col>
          </ez-atom-row>
          <!-- END row -->
        </ez-loading-block>
      </ez-atom-container>

      <transition name="fade" v-on:enter="showMenu = true">
        <div
          v-if="previewTheme"
          :class="$style['fixed-right-box']"
          @click.self="showMenu = false"
        >
          <transition name="transform" v-on:after-leave="closeTemplates">
            <ezf-wizard-themes-prewiew-steps
              v-if="showMenu"
              :theme="previewTheme"
              @close="showMenu = false"
              @use-theme="useTheme"
            />
          </transition>
        </div>
      </transition>
    </div>

    <ezf-wizard-footer slot="footer">
      <ez-atom-button
        variation="outline"
        color="neutral"
        @click="$router.push(previousRoute)"
        :href="$router.resolve(previousRoute).href"
        data-test="go-back"
      >
        {{ $t("wizard.back") }}
      </ez-atom-button>
      <ez-atom-button
        color="funnel"
        :disabled="!currentTheme"
        @click="useTheme(currentTheme)"
        data-test="use-template"
      >
        {{ $t("wizard.useTheme") }}
      </ez-atom-button>
    </ezf-wizard-footer>
  </ez-layout>
</template>

<style src="@/pages/wizard/styles/animations.scss" lang="scss" scoped></style>
<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
