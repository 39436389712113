<script>
import WzFunnelItem from './item.vue';

export default {
  name: 'ezfWizardList',
  components: {
    WzFunnelItem,
  },
  props: ['funnels'],
};
</script>

<template>
  <ez-atom-row class="mt-5 flex-wrap" :gutter="24">
    <wz-funnel-item
      v-for="funnel in funnels"
      :funnel="funnel"
      :key="funnel.id"
    />
  </ez-atom-row>
</template>
