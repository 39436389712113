<script>
import { mapActions, mapMutations } from 'vuex';
import ezfPageHeader from '@/pages/layout/header.vue';
import { SUBMIT } from '@/store/wizard/form/actions';

import { CLEAN } from '@/store/wizard/form/mutations';
import WzForm from './components/form.vue';
import WzSummary from './components/summary.vue';

export default {
  name: 'ezfWizard1FinishPage',
  components: {
    WzSummary,
    WzForm,
    ezfPageHeader,
  },
  data() {
    return {
      saving: false,
    };
  },
  methods: {
    ...mapActions('wizard/form', [SUBMIT]),
    ...mapMutations('wizard/form', [CLEAN]),
    async createFunnel() {
      const isValid = await this.$refs.form.$validator.validate();
      if (isValid) {
        try {
          this.saving = true;
          const newFunnel = await this[SUBMIT]({ themeId: this.$route.params.theme });
          if (newFunnel && newFunnel.id) {
            await this.$router.push({
              name: 'funnelBuilder',
              params: { funnel_id: newFunnel.id },
            });
            this[CLEAN]();
          } else if (newFunnel && newFunnel.error) {
            // eslint-disable-next-line no-alert
            window.alert(newFunnel.message);
          }
        } catch (error) {
          // eslint-disable-next-line no-alert
          window.alert(error.message);
        } finally {
          this.saving = false;
        }
      }
    },
  },
};
</script>

<template>
  <ez-layout name="funnels">
    <ezf-page-header slot="header" />
    <ez-atom-container slot="content" :class="$style['content-new-wizard']">
      <ez-atom-row :gutter="24">
        <ez-atom-col :size="16">
          <ez-atom-box>
            <div :class="$style['box-header-wrap']" slot="header">
              <div :class="[$style['heading'], $style['is-big']]">
                {{ $t("wizard.finish.title") }}
              </div>
            </div>
            <div slot="content">
              <div :class="[$style['heading'], $style['is-gray-300']]">
                {{ $t("wizard.finish.info") }}
              </div>
              <wz-form ref="form" />
            </div>
          </ez-atom-box>
        </ez-atom-col>
        <ez-atom-col :size="8">
          <wz-summary />
        </ez-atom-col>
      </ez-atom-row>
    </ez-atom-container>

    <ezf-wizard-footer slot="footer">
      <ez-atom-button
        variation="outline"
        color="neutral"
        @click="
          $router.push({
            name: 'wizard-themes',
            query: { selected: $route.params.theme }
          })
        "
        :href="
          $router.resolve({
            name: 'wizard-themes',
            query: { selected: $route.params.theme }
          }).href
        "
        data-test="go-back"
      >
        {{ $t("wizard.back") }}
      </ez-atom-button>
      <ez-atom-button
        color="funnel"
        @click="createFunnel"
        :disabled="saving || ($refs.form && $refs.form.errors.any())"
        :preloader="saving"
        :title="$refs.form && $refs.form.errors.first('*')"
        data-test="finish-setup"
      >
        {{ $t("wizard.finish.button") }}
      </ez-atom-button>
    </ezf-wizard-footer>
  </ez-layout>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
