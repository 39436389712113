<script>
import { createNamespacedHelpers } from 'vuex';
import { GET_TEMPLATE_PREVIEW_URL } from '@/store/wizard/themes/getters';

const { mapGetters } = createNamespacedHelpers('wizard/themes');

export default {
  name: 'ezfWizardThemesPrewiewStepTemplate',
  props: ['id', 'preview'],
  computed: {
    ...mapGetters({ getUrl: GET_TEMPLATE_PREVIEW_URL }),
    href() {
      return this.getUrl(this.id);
    },
  },
};
</script>

<template>
  <div :class="['template-preview', $style['pages-image']]">
    <img :class="$style['pages-image__image']" :src="preview" alt="" />
    <div :class="$style['theme-box-overlay']">
      <ez-atom-button
        color="neutral"
        icon="security-show"
        :class="$style['theme-box-overlay__button']"
        target="_blank"
        :href="href"
        data-test="show-page"
      />
    </div>
  </div>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>

<style scoped>
.template-preview {
  min-height: 91.172px;
}
</style>
