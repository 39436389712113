<script>
import store from '@/store';
import { SET_TYPE_SLUG } from '@/store/wizard/mutations';

function setFunnelTypeSlug(slug) {
  store.commit(`wizard/${SET_TYPE_SLUG}`, slug);
}

export default {
  name: 'ezfWizardFunnelRoute',

  beforeRouteEnter(to, from, next) {
    setFunnelTypeSlug(to.params.type);
    next();
  },

  beforeRouteUpdate(to, from, next) {
    if (store.state.wizard.typeSlug !== to.params.type) {
      setFunnelTypeSlug(to.params.type);
    }
    next();
  },

  beforeRouteLeave(to, from, next) {
    next();
  },
};
</script>

<template>
  <router-view />
</template>
