<script>
export default {
  name: 'ezfWizardCards',
  props: ['funnels'],
  data() {
    return {
      images: require.context('@/assets/funnel-steps-main', false, /\.png$/),
    };
  },
};
</script>

<template>
  <ez-atom-row class="mt-5 flex-wrap" :gutter="24">
    <ez-atom-col
      v-for="funnel in funnels"
      :key="funnel.id"
      :size="6"
      class="d-flex mb-4"
    >
      <div :class="[$style['funnel-box'], $style['view-cards']]">
        <div :class="$style['funnel-box__image-container']">
          <img
            :class="$style['funnel-box__image']"
            :src="images(`./${funnel.image || 'main-default.png'}`)"
            alt=""
          />
        </div>
        <div :class="$style['funnel-box__info']">
          <div :class="$style['heading']">
            {{ $t(`wizard.funnels.${funnel.id}.short_title`) }}
          </div>
          <div :class="$style['text']">
            {{ $t(`wizard.funnels.${funnel.id}.description`) }}
          </div>
          <ez-atom-button
            :block="true"
            color="funnel"
            variation="outline"
            :class="$style['funnel-box__button']"
            @click="
              $router.push({
                name: 'wizard-themes',
                params: { type: funnel.slug }
              })
            "
            :href="
              $router.resolve({
                name: 'wizard-themes',
                params: { type: funnel.slug }
              }).href
            "
            :data-test="`create-a-funnel-${funnel.slug}`"
          >
            {{ $t("wizard.createButton") }}
          </ez-atom-button>
        </div>
      </div>
    </ez-atom-col>
  </ez-atom-row>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
