import { slugify } from 'transliteration';

function nameToSlug(name) {
  return slugify(name, { allowedChars: 'a-zA-Z0-9_' });
}

export default {
  methods: {
    slugInput({ target }) {
      const { value, selectionStart, selectionEnd } = target;
      const slug = nameToSlug(`${value}a`).slice(0, -1); // hack with "a" to allow enter "-" to end
      target.value = slug;
      const { length } = slug;
      target.selectionStart = Math.min(length, selectionStart);
      target.selectionEnd = Math.min(length, selectionEnd);
      this.slug = slug;
    },
  },
  watch: {
    name: {
      immediate: true,
      handler(value, oldValue) {
        const currentSlug = nameToSlug(this.slug);
        if (currentSlug === '' || currentSlug === nameToSlug(oldValue)) {
          this.slug = nameToSlug(value);
        }
      },
    },
  },
};
