<script>
import swiperMixin from '@/mixins/swiper';
import WzFunnelMap from '../funnel-map.vue';
import ezfWizardArrows from '../arrows.vue';

export default {
  name: 'ezfWizardFunnelItem',
  props: ['funnel'],
  mixins: [swiperMixin],
  components: { WzFunnelMap, ezfWizardArrows },
};
</script>

<template>
  <ez-atom-col :size="24" class="d-flex mb-4">
    <div :class="[$style['funnel-box'], $style['view-list']]">
      <div :class="$style['funnel-box__info']">
        <div :class="$style['heading']">
          {{ $t(`wizard.funnels.${funnel.id}.short_title`) }}
        </div>
        <div :class="$style['text']">
          {{ $t(`wizard.funnels.${funnel.id}.description`) }}
        </div>

        <ez-atom-button
          color="funnel"
          variation="outline"
          :class="$style['funnel-box__button']"
          @click="
            $router.push({
              name: 'wizard-themes',
              params: { type: funnel.slug }
            })
          "
          :href="
            $router.resolve({
              name: 'wizard-themes',
              params: { type: funnel.slug }
            }).href
          "
          :data-test="`create-a-funnel-${funnel.slug}`"
        >
          {{ $t("wizard.createButton") }}
        </ez-atom-button>

        <ezf-wizard-arrows
          :class="$style['funnel-box__button-group']"
          v-bind="{ isBeginning, isEnd }"
          ref="arrows"
        />
      </div>

      <div :class="$style['funnel-box__roadmap']">
        <wz-funnel-map
          v-if="funnel.funnelmap"
          :model="funnel.funnelmap"
          :class="$style['is-slider']"
          ref="container"
        />
      </div>
    </div>
  </ez-atom-col>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
