<script>
export default {
  name: 'ezfWizardThemesTemplate',
  props: ['step', 'value', 'id', 'preview', 'templateId'],
  model: {
    prop: 'value',
    event: 'change',
  },
  methods: {
    change(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<template>
  <ez-atom-select-box
    @change="change"
    :model="value"
    :value="templateId"
    :name="step.type"
    :id="id"
    :mark-unchecked="false"
    :styles="{
      paddingContent: 0,
      iconSize: 20,
      iconRatio: 32
    }"
    class="template-select-box"
  >
    <img :class="$style['pages-image__image']" :src="preview" alt="" />
  </ez-atom-select-box>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>

<style scoped>
.template-select-box {
  min-height: 133px;
}
</style>
