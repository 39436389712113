<script>
import ezfPageHeader from '@/pages/layout/header.vue';
import ezfPageFooter from '@/pages/layout/footer.vue';
import MASTER from '@/enums/funnel-wizard';
import WzHeader from './components/header.vue';
import WzFunnelMap from './components/funnel-map.vue';
import WzContent from './components/content.vue';
import WzItem from './components/item.vue';
import queryToString from '../../helpers/queryToString';

export default {
  name: 'ezfWizard1TypePage',
  components: {
    ezfPageHeader,
    ezfPageFooter,
    WzHeader,
    WzFunnelMap,
    WzContent,
    WzItem,
  },
  data() {
    const selected = [];
    let item = MASTER;
    while (typeof this.$route.query[item.name] !== 'undefined') {
      const { type, name } = item;
      let value = this.$route.query[name];
      if (type === 'boolean') {
        value = value.toString() === 'true';
      }
      const option = item.options.find((o) => o.value === value);
      if (option) {
        selected.push({ name, type, value });
        item = option.next;
      } else {
        break;
      }
    }
    selected.push({ value: null });
    return {
      selected,
    };
  },
  methods: {
    change({ index, item, value }) {
      const { name, type } = item;
      const cut = this.selected.length - index;
      this.selected.splice(index, cut, { name, type, value }, { value: null });
      if (item.type !== 'funnel') {
        const { protocol, host, pathname } = window.location;
        const query = queryToString(this.query);
        const url = `${protocol}//${host}${pathname}?${query}`;
        window.history.pushState({ path: url }, '', url);
      }
    },
  },
  computed: {
    query() {
      const query = {};
      this.selected.forEach(({ name, value }) => {
        if (name) {
          query[name] = value;
        }
      });
      return query;
    },
    master() {
      try {
        const items = [];
        const images = [];
        const master = {
          funnelmap: null,
          items,
          images,
          funnelmapTestAttrs: MASTER.funnelmapTestAttrs,
        };
        let item = MASTER;
        items.push(item);
        if (item.main_image) {
          images.unshift(item.main_image);
        }
        this.selected.some(({ name, type, value }) => {
          if (item.type !== type) {
            return true;
          }
          if (type !== 'funnel' && item.name !== name) {
            return true;
          }
          const option = item.options.find((o) => o.value === value);
          if (option) {
            if (option.funnelmap) {
              master.funnelmap = option.funnelmap;
            }
            if (option.main_image) {
              images.unshift(option.main_image);
            }
            item = option.next;
            items.push(item);
            if (item.options) {
              return false;
            }
          }
          return true;
        });
        return master;
      } catch (e) {
        console.log(e);
      }
      return {};
    },
  },
};
</script>

<template>
  <ez-layout name="funnels">
    <ezf-page-header slot="header" />
    <ez-atom-container slot="content" :class="$style['content-new-wizard']">
      <ez-atom-row>
        <ez-atom-col>
          <ez-atom-box>
            <wz-header slot="header" />
            <wz-funnel-map
              :model="master.funnelmap"
              :test-attrs="master.funnelmapTestAttrs"
              v-if="master.funnelmap"
              slot="content"
            />
          </ez-atom-box>
          <wz-content :images="master.images">
            <wz-item
              v-for="(item, index) in master.items"
              :model="selected[index].value"
              :key="index"
              :index="index"
              :item="item"
              @change="change"
            />
          </wz-content>
        </ez-atom-col>
      </ez-atom-row>
    </ez-atom-container>
    <ezf-page-footer slot="footer" />
  </ez-layout>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
