<script>
const components = require.context('./items/', false, /\.vue$/);

export default {
  name: 'ezfWizardItem',
  props: ['index', 'item', 'model'],
  computed: {
    title() {
      const key = `wizard.questions.${this.item.i18n}.title`;
      return this.$te(key) ? this.$t(key) : this.item.title;
    },
    component() {
      return components(`./${this.item.type}.vue`).default;
    },
  },
  methods: {
    change(value) {
      const { index, item } = this;
      this.$emit('change', { index, item, value });
    },
  },
};
</script>

<template>
  <component
    :is="component"
    v-bind="item"
    :title="title"
    :model="model"
    @change="change"
    :class="{ 'mt-4': index > 0 }"
  />
</template>
