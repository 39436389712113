<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { GET_FUNNEL, GET_TYPE_QUERY } from '@/store/wizard/getters';
import { LOAD_THEME } from '@/store/wizard/themes/actions';
import WzPanel from './panel.vue';

export default {
  name: 'ezfWizardSummary',
  components: {
    WzPanel,
  },
  computed: {
    ...mapGetters('wizard', { funnel: GET_FUNNEL, query: GET_TYPE_QUERY }),
    ...mapState('wizard/themes', ['theme']),
    typeRoute() {
      return { name: 'wizard-goal', query: this.query };
    },
  },
  methods: {
    ...mapActions('wizard/themes', [LOAD_THEME]),
  },
  created() {
    this[LOAD_THEME](this.$route.params.theme);
  },
};
</script>

<template>
  <ez-atom-box>
    <div :class="$style['box-header-wrap']" slot="header">
      <div :class="[$style['heading'], $style['is-big']]">
        {{ $t("wizard.summary.title") }}
      </div>
    </div>

    <div slot="content">
      <wz-panel>
        <template slot="type">
          {{ $t("wizard.summary.type") }}
        </template>
        <template slot="title">
          {{ $t(`wizard.funnels.${funnel.value}.title`) }}
        </template>
        <template slot="buttons">
          <ez-atom-button
            color="secondary"
            variation="outline"
            @click="$router.push(typeRoute)"
            :href="$router.resolve(typeRoute).href"
            data-test="change-funnel-type"
          >
            {{ $t("wizard.summary.change") }}
          </ez-atom-button>
        </template>
      </wz-panel>
      <wz-panel>
        <template slot="type">
          {{ $t("wizard.summary.template") }}
        </template>
        <template slot="title">{{ theme && theme.name }}</template>
        <template slot="buttons">
          <ez-atom-button
            color="secondary"
            variation="outline"
            @click="
              $router.push({
                name: 'wizard-themes',
                query: { selected: $route.params.theme }
              })
            "
            :href="
              $router.resolve({
                name: 'wizard-themes',
                query: { selected: $route.params.theme }
              }).href
            "
            data-test="change-template"
          >
            {{ $t("wizard.summary.change") }}
          </ez-atom-button>
        </template>
      </wz-panel>
    </div>
  </ez-atom-box>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
