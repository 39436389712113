<script>
import { createNamespacedHelpers } from 'vuex';
import { LOAD_CATEGORIES } from '@/store/wizard/themes/actions';
import { GET_CATEGORIES } from '@/store/wizard/themes/getters';

const { mapActions, mapGetters } = createNamespacedHelpers('wizard/themes');

export default {
  name: 'ezfWizardThemesCategories',
  computed: {
    ...mapGetters({ categories: GET_CATEGORIES }),
    selected() {
      return this.$route.query.category;
    },
  },
  created() {
    this.load();
  },
  methods: {
    ...mapActions({ load: LOAD_CATEGORIES }),
    setCategory(id) {
      this.$router.replace({ query: { ...this.$route.query, category: id } });
    },
  },
};
</script>

<template>
  <ez-atom-row>
    <ez-atom-col>
      <div :class="$style['filter-box']">
        <ez-atom-button-group :class="$style['filter-box__filter']" wrap>
          <ez-atom-button
            @click="setCategory()"
            :color="selected ? 'neutral' : 'funnel'"
            data-test="category-all"
          >
            {{ $t("wizard.themes.categories.all") }}
          </ez-atom-button>
          <ez-atom-button
            v-for="(item, index) in categories"
            @click="setCategory(item.id)"
            :key="item.id"
            :color="selected === item.id ? 'funnel' : 'neutral'"
            :data-test="`category-${index}`"
          >
            {{ item.name }}
          </ez-atom-button>
        </ez-atom-button-group>
      </div>
    </ez-atom-col>
  </ez-atom-row>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
