<script>
export default {
  name: 'ezfWizardView',
  props: {
    selected: {
      default: 'list',
    },
  },
  model: {
    prop: 'selected',
    event: 'change',
  },
  data() {
    return {
      views: [
        {
          id: 'list',
          icon: 'table-list',
        },
        {
          id: 'cards',
          icon: 'table-grid',
        },
      ],
    };
  },
  methods: {
    change(value) {
      this.$router.replace({ query: { ...this.$route.query, view: value } });
      this.$emit('change', value);
      localStorage.setItem('wizard-funnels-view', value);
    },
  },
};
</script>

<template>
  <ez-atom-button-group :class="$style['filter-box__view']">
    <ez-atom-button
      v-for="view in views"
      :key="view.id"
      :icon="view.icon"
      :color="view.id == selected ? 'funnel' : 'neutral'"
      :class="view.id == selected && $style['is-active']"
      variation="outline"
      :title="$t(`wizard.views.${view.id}`)"
      :data-test="`view-${view.id}`"
      @click="change(view.id)"
    ></ez-atom-button>
  </ez-atom-button-group>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
