<script>
import WzImage from './image.vue';

export default {
  name: 'ezfWizardContent',
  props: ['images'],
  components: {
    WzImage,
  },
};
</script>

<template>
  <ez-atom-row :gutter="32" class="mt-4">
    <ez-atom-col :size="16">
      <slot />
    </ez-atom-col>
    <wz-image :images="images" />
  </ez-atom-row>
</template>
