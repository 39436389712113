import Vue from 'vue';
import Swiper from 'swiper';
import 'swiper/swiper.scss';

const swiperMixin = {
  data() {
    return {
      isBeginning: true,
      isEnd: true,
    };
  },
  methods: {
    initSwiper() {
      const { arrows, container } = this.$refs;

      const containerElement = container instanceof Vue ? container.$el : container;
      const swiper = new Swiper(containerElement, {
        init: false,
        grabCursor: true,
        spaceBetween: 16,
        threshold: 5,
        slidesPerView: 'auto',
        watchOverflow: true,
      });

      // Fix navigation button next/prev
      Object.keys(arrows.$refs).forEach((key) => {
        arrows.$refs[key].$on('click', (e) => {
          e.preventDefault();
          swiper[key]();
        });
      });

      for (const event of ['init', 'slideChange', 'resize', 'fromEdge', 'reachBeginning', 'reachEnd']) {
        swiper.on(event, () => {
          this.isEnd = swiper.isEnd;
          this.isBeginning = swiper.isBeginning;
        });
      }
      swiper.init();
    },
  },
  mounted() {
    this.initSwiper();
  },
};

export default swiperMixin;
