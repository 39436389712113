export default function queryToString(query) {
  const result = [];
  for (const p in query) {
    if (Object.prototype.hasOwnProperty.call(query, p)) {
      result.push(`${encodeURIComponent(p)}=${encodeURIComponent(query[p])}`);
    }
  }

  return result.join('&');
}
