<script>
export default {
  name: 'ezfWizardFunnelYesNo',
  props: ['title', 'name', 'model'],
  model: {
    prop: 'model',
    event: 'change',
  },
  computed: {
    ask() {
      return typeof this.model !== 'boolean';
    },
    classColor() {
      return this.model ? this.$style['color-success'] : this.$style['color-danger'];
    },
    valueText() {
      return this.$t(`wizard.questions.boolean.${this.model ? 'yes' : 'no'}`);
    },
  },
};
</script>

<template>
  <ez-atom-box>
    <template slot="header">
      <div :class="$style['box-header-wrap']">
        <div :class="[$style['heading'], model && $style['is-gray']]">
          {{ title }}
          <span v-if="!ask" :class="classColor">{{ valueText }}</span>
        </div>
        <ez-atom-button-group :class="$style['box-header-buttons']">
          <template v-if="ask">
            <ez-atom-button
              color="danger"
              icon="math-default-false"
              data-test="cross"
              @click="$emit('change', false)"
            />
            <ez-atom-button
              color="success"
              icon="math-default-true"
              data-test="tick"
              @click="$emit('change', true)"
            />
          </template>
          <ez-atom-button
            v-else
            color="neutral"
            variation="outline"
            data-test="change"
            @click="$emit('change', null)"
          >
            {{ $t("wizard.change") }}
          </ez-atom-button>
        </ez-atom-button-group>
      </div>
    </template>
  </ez-atom-box>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
