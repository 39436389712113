import { mapGetters } from 'vuex';
import LOCALES from '@/enums/locales';

export default {
  data() {
    return {
      localesList: [
        {
          label: this.$t('English'),
          value: LOCALES.enEN,
        },
        {
          label: this.$t('Deutsch'),
          value: LOCALES.deDE,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['mainUserLocale']),
  },
  methods: {
    setDefaultLocale() {
      this.locale = this.mainUserLocale;
    },
  },
};
