<script>
import { mapGetters } from 'vuex';
import { GET_FUNNEL } from '@/store/wizard/getters';

export default {
  name: 'ezfWizardFooter',
  computed: {
    ...mapGetters('wizard', { funnel: GET_FUNNEL }),
  },
};
</script>

<template>
  <div :class="$style['fixed-bottom-box']">
    <ez-atom-container>
      <ez-atom-row>
        <ez-atom-col class="pr-5">
          <i18n
            path="wizard.creatingAFunnel"
            tag="div"
            :class="$style['heading']"
          >
            <span
              :class="$style['color-brand-funnels-400']"
              data-test="wizard-heading"
            >
              {{ $t(`wizard.funnels.${funnel.value}.title`) }}
            </span>
          </i18n>
        </ez-atom-col>
        <ez-atom-col size="auto">
          <ez-atom-button-group>
            <slot />
          </ez-atom-button-group>
        </ez-atom-col>
      </ez-atom-row>
    </ez-atom-container>
  </div>
</template>

<style src="../styles/wizard.scss" lang="scss" module></style>
