<script>
export default {
  name: 'ezfWizardTypes',
  props: {
    selected: {
      default: 'all',
    },
  },
  model: {
    prop: 'selected',
    event: 'change',
  },
  data() {
    return {
      filters: [
        'all',
        'sales',
        'webinar',
        'optin',
        'applicationform',
        'affiliate',
        'free',
      ],
    };
  },
  methods: {
    change(value) {
      this.$router.replace({ query: { ...this.$route.query, type: value } });
      this.$emit('change', value);
    },
  },
};
</script>

<template>
  <ez-atom-button-group :class="$style['filter-box__filter']">
    <ez-atom-button
      v-for="filter in filters"
      :key="filter"
      :color="filter === selected ? 'funnel' : 'neutral'"
      :data-test="`filter-${filter}`"
      @click="change(filter)"
    >
      {{ $t(`wizard.types.${filter}`) }}
    </ez-atom-button>
  </ez-atom-button-group>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
