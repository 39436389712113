<script>
import { mapGetters, mapMutations } from 'vuex';
import swiperMixin from '@/mixins/swiper';
import { GET_SELECTED_TEMPLATE } from '@/store/wizard/themes/getters';
import { SET_SELECTED_TEMPLATE } from '@/store/wizard/themes/mutations';
import ezfWizardThemesTemplate from './template.vue';
import ezfWizardArrows from '../arrows.vue';

export default {
  name: 'ezfWizardThemesStep',
  props: ['step', 'templates'],
  mixins: [swiperMixin],
  components: {
    ezfWizardThemesTemplate,
    ezfWizardArrows,
  },
  computed: {
    ...mapGetters('wizard/themes', [GET_SELECTED_TEMPLATE]),
    value() {
      return this[GET_SELECTED_TEMPLATE](this.step.num);
    },
  },
  methods: {
    ...mapMutations('wizard/themes', [SET_SELECTED_TEMPLATE]),
    change(value) {
      this[SET_SELECTED_TEMPLATE]({ stepNum: this.step.num, value });
    },
  },
};
</script>

<template>
  <ez-atom-col :size="24" class="d-flex mb-4">
    <div :class="[$style['funnel-box'], $style['view-list']]">
      <div :class="$style['funnel-box__info']">
        <div :class="$style['heading']">
          {{ $t(`wizard.steps.${step.slug}.title`) }}
        </div>
        <div :class="$style['text']">
          {{ $t(`wizard.steps.${step.slug}.description`) }}
        </div>
        <ezf-wizard-arrows
          :class="$style['funnel-box__button-group']"
          v-bind="{ isBeginning, isEnd }"
          ref="arrows"
        />
      </div>
      <div :class="$style['pages-selector']" ref="container">
        <div :class="[$style['pages-selector__wrapper'], 'swiper-wrapper']">
          <ezf-wizard-themes-template
            v-for="(template, i) in templates"
            :value="value"
            @change="change"
            :key="template.id"
            :step="step"
            :data-test="`step-template-${i}`"
            v-bind="template"
            class="swiper-slide"
          />
        </div>
      </div>
    </div>
  </ez-atom-col>
</template>

<style src="@/pages/wizard/styles/wizard.scss" lang="scss" module></style>
